import {
  createEffect,
  createMemo,
  createResource,
  createSignal,
  For,
  Index,
  Match,
  Suspense,
  Switch,
} from "solid-js";
import { placeholderCard } from "~/assets/assets";
import { getBrand } from "~/server/apis/client_apis";
import { VoucherProduct } from "~/server/types/brand";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { useModal } from "../modal";
import { getBrandTncData } from "~/server/data/brand_tnc_data";
import SolidMarkdown from "solid-markdown";
import { CartProduct } from "~/context/procurement";
import { toRupees } from "~/utils/number";

type DenominationDetailsModalProps = {
  brandId: string;
  discountPercentage: number;
  balance: number;
  addToCart: (newProduct: CartProduct) => void;
};

export const DenominationDetailsModal = ({
  brandId,
  discountPercentage,
  addToCart,
  balance,
}: DenominationDetailsModalProps) => {
  const [brandDetails] = createResource<VoucherProduct>(async () => {
    return getBrand(brandId);
  });

  const customAmountDefaultDenominationsList = createMemo(() => {
    const denominations = [
      100, 250, 500, 750, 1000, 1500, 2000, 2500, 5000, 10000,
    ];
    return denominations.filter(
      (denomination) =>
        denomination >= (brandDetails()?.amountConditions?.minAmount ?? 0) &&
        denomination <= brandDetails()?.amountConditions?.maxAmount!
    );
  });

  const denominationList = createMemo(() => {
    if (brandDetails.state !== "ready") return [];
    return (
      brandDetails()?.amountConditions?.denominations ??
      customAmountDefaultDenominationsList()
    ).map((denomination: any) => {
      return {
        denomination,
        quantity: 0,
      };
    });
  });

  const [denominationsWithQuantity, setDenominationsWithQuantity] =
    createSignal<{ denomination: number; quantity: number }[]>([]);

  createEffect(() => {
    if (brandDetails.state === "ready") {
      setDenominationsWithQuantity(denominationList());
    }
  });

  const handleQuantityChange = (index: number, newQuantity: number) => {
    setDenominationsWithQuantity((prev: any[]) =>
      prev.map((item: any, i: number) =>
        i === index
          ? {
              ...item,
              quantity: Math.max(0, newQuantity),
            }
          : item
      )
    );
  };

  const handleInputChange = (index: number, event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);

    if (!isNaN(value)) {
      handleQuantityChange(index, value);
    }
  };

  const calculateTotalAmount = () => {
    return denominationsWithQuantity().reduce(
      (total: number, item: { denomination: number; quantity: number }) => {
        return total + item.denomination * item.quantity;
      },
      0
    );
  };

  const calculateDiscount = (totalAmount: number) => {
    return (totalAmount * discountPercentage) / 100;
  };

  const totalAmount = createMemo(() => calculateTotalAmount());
  const discountAmount = createMemo(() => calculateDiscount(totalAmount()));
  const finalAmount = createMemo(() => totalAmount() - discountAmount());

  const disableAddToCart = createMemo(() =>
    denominationsWithQuantity().every(
      (item: { quantity: number }) => item.quantity === 0
    )
  );

  const { openModal, closeModal } = useModal()!;

  const openBrandDetailsModal = () => {
    const [response] = createResource<string>(async () => {
      return getBrandTncData(
        brandDetails()?.voucherProductMetadata.tncResId ?? ""
      );
    });

    openModal(() => {
      return (
        <div class="m-2 flex w-full flex-col gap-4">
          <Switch>
            <Match when={brandDetails.state === "ready"}>
              <p class="text-h4 text-textDark">
                {brandDetails()?.name} gift card at{" "}
                <span class="text-successDark">{discountPercentage}% off</span>
              </p>
              <img
                src={brandDetails()?.voucherProductMetadata?.heroImageUrl}
                alt={`${brandDetails()?.voucherProductMetadata.title}`}
                class=" w-[480px] rounded-xl"
              />

              <div class="flex ">
                <p class="text-bold text-textDark">About this gift card</p>
                {/* <p>{brandDetails()?.voucherProductMetadata.title}</p> */}
              </div>
              <hr class="h-px w-full border border-baseTertiaryDark" />

              <div class="flex flex-col gap-4">
                <p class="text-bold text-textDark">How to redeem</p>
                <For
                  each={brandDetails()?.voucherProductMetadata.howItWorksSteps}
                >
                  {(steps: { description: any }, index: () => number) => {
                    return (
                      <div class="flex items-start gap-2">
                        <div class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-baseTertiaryMedium text-[10px] font-semibold text-textDark">
                          {index() + 1}
                        </div>
                        {steps.description}
                      </div>
                    );
                  }}
                </For>
              </div>
              <hr class="h-px w-full border border-baseTertiaryDark" />
              <div class="flex flex-col gap-4">
                <p class="text-bold text-textDark">Things to note</p>
                <Switch>
                  <Match when={response.state === "ready"}>
                    <For each={response()!.split("\n")}>
                      {(tnc: string, index: () => number) => {
                        return (
                          <div class="flex items-start gap-2">
                            <div class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-baseTertiaryMedium text-[10px] font-semibold text-textDark">
                              {index() + 1}
                            </div>
                            <SolidMarkdown>
                              {tnc.replace("- ", "")}
                            </SolidMarkdown>
                          </div>
                        );
                      }}
                    </For>
                  </Match>
                </Switch>
              </div>
            </Match>
          </Switch>
        </div>
      );
    }, "md:w-[512px]");
  };

  const handleAddToCart = () => {
    const selectedDenominations = denominationsWithQuantity().filter(
      (item: { quantity: number }) => item.quantity > 0
    );

    selectedDenominations.forEach(
      (item: { denomination: number; quantity: number }) => {
        const totalAmountForDenomination = item.denomination * item.quantity;

        const product: CartProduct = {
          detail: {
            logoUrl: brandDetails()?.voucherProductMetadata?.iconImageUrl!,
            brandName: brandDetails()?.name!,
          },
          productId: brandId,
          denomination: item.denomination,
          amount: totalAmountForDenomination,
          quantity: item.quantity,
        };

        addToCart(product);
      }
    );

    closeModal();
  };

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <Switch>
        <Match when={brandDetails.state === "pending"}>
          <DottedLoader color="#999" />
        </Match>
        <Match when={brandDetails.state === "errored"}>
          <div>Error loading brand details: {brandDetails.error}</div>
        </Match>
        <Match when={brandDetails.state === "ready"}>
          <div class="flex w-full flex-col gap-5 rounded-[20px] pt-8">
            <div class="flex flex-col items-center justify-center gap-2">
              <HubbleImage
                src={brandDetails()?.voucherProductMetadata?.iconImageUrl!}
                class="inline-block h-[88px] w-[78px] rounded-xl"
                alt={`${brandDetails()?.name} logo image`}
                errorImage={placeholderCard}
              />
              <p class="text-h4 text-textDark">
                {brandDetails()?.name} gift card
              </p>
              {/* <button
                class="text-medium text-textNormal underline"
                onClick={() => {
                  closeModal();
                  openBrandDetailsModal();
                }}
              >
                Details
              </button> */}
            </div>
            <div class="noScrollbar m-auto flex flex-col gap-3 overflow-y-auto">
              <Index each={denominationsWithQuantity()}>
                {(denomination, index) => (
                  <div class="flex w-[316px] items-center justify-between rounded-xl bg-gray-100 p-3">
                    <p class="text-mediumBold text-textDark">
                      ₹{denomination().denomination}
                    </p>
                    <div class="inline-block rounded-lg bg-basePrimaryDark">
                      <div class="flex items-center">
                        <button
                          type="button"
                          class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md  font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                          onClick={() =>
                            handleQuantityChange(
                              index,
                              denomination().quantity - 1
                            )
                          }
                          disabled={denomination().quantity === 0}
                        >
                          <PhosphorIcon
                            name="minus"
                            fontSize={12}
                            size="bold"
                            class="text-white"
                          />
                        </button>
                        <input
                          class="[&::-webkit-outer-spin-button] :appearance-none w-6 border-0 bg-transparent p-0 text-center text-f12Bold text-white hover:underline focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none"
                          type="number"
                          value={denomination().quantity}
                          onInput={(e) => handleInputChange(index, e)}
                        />
                        <button
                          type="button"
                          class="text-sm shadow-sm inline-flex size-6 items-center justify-center gap-x-2 rounded-md font-medium text-white focus:outline-none disabled:pointer-events-none disabled:opacity-50"
                          onClick={() =>
                            handleQuantityChange(
                              index,
                              denomination().quantity + 1
                            )
                          }
                        >
                          <PhosphorIcon
                            name="plus"
                            fontSize={12}
                            size="bold"
                            class="text-white"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Index>
            </div>
            <div class="sticky bottom-0 flex flex-col gap-3 border-t border-baseTertiaryDark bg-white px-2 pb-2 pt-3">
              <Switch>
                <Match when={!discountPercentage && !disableAddToCart()}>
                  <p class="text-center text-subtitleSmall text-textDark">
                    You pay {toRupees(totalAmount())}
                  </p>
                </Match>
                <Match when={disableAddToCart()}>
                  <p class="text-center text-medium text-textDark">
                    Please add gift card denominations to buy
                  </p>
                </Match>
                <Match when={!disableAddToCart()}>
                  <div class="flex justify-between">
                    <p class="text-center text-subtitleSmall text-textDark">
                      You pay{" "}
                      <span class="text-successDark">
                        {toRupees(finalAmount())}
                      </span>{" "}
                      <span class="text-medium text-textNormal line-through">
                        {toRupees(totalAmount())}
                      </span>
                    </p>
                    <p class="text-subtitleSmall text-successDark">
                      Instant {discountPercentage}% off
                    </p>
                  </div>
                </Match>
              </Switch>
              <button
                class="flex w-full items-center justify-center gap-1 rounded-lg bg-basePrimaryDark px-4 py-2.5 text-subtitleSmall text-white disabled:bg-baseSecondaryLight"
                disabled={disableAddToCart()}
                onClick={handleAddToCart}
              >
                <PhosphorIcon name="shopping-cart-simple" />
                Add to cart
              </button>
            </div>
          </div>
        </Match>
      </Switch>
    </Suspense>
  );
};
